'use client';
import { IC_ARROWDROPDOWN } from '@/assets/icons';
import useLoginModel from '@/contexts/LoginModelProvider';
import useDebounce from '@/services/hooks/useDebounce';
import useDeepCompareMemo from '@/services/hooks/useDeepCompareMemo';
import useSearchHistory from '@/services/hooks/useSearchHistory';
import { searchParamsToObject } from '@/services/utils/helper';
import cls from 'classnames';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';
import { MdCancel, MdOutlineSearch } from 'react-icons/md';
import styles from './SearchInput.module.scss';
import { useAlert } from '@/contexts/AlertProvider';
const SearchInput = () => {
	const pathname = usePathname() as string;
	const router = useRouter();
	const toast = useAlert();
	const [isLabTest, setIsLabTest] = useState(false);
	const { searchValue, setSearchValue } = useLoginModel();
	const search = useSearchParams() as URLSearchParams;
	const _product_type = search.get('_product_type') || 'all';
	const [selectedValue, setSelectedValue]: any = useState<object>({
		value: _product_type === 'labtest' ? 'labtest' : 'all',
		label: _product_type === 'labtest' ? 'Lab Test' : 'All'
	});
	const [history, updateHistory] = useSearchHistory();
	const debouncedSearchValue = useDebounce(searchValue, 500); // 500ms delay
	const searchBoxRef = useRef(null);
	const [isFocused, setIsFocused] = useState(false);

	const handleBlur = () => setIsFocused(false);
	const medicineQuery = {
		_perPage: 10,

		...searchParamsToObject(search),
		_product_type: selectedValue.value,
		_search: debouncedSearchValue || ''
	};
	const isSearchPage = pathname.startsWith('/search');

	const memoizedFilter = useDeepCompareMemo(medicineQuery) as any;
	const { _perPage, ...rest } = memoizedFilter;
	useEffect(() => {
		isLabTest
			? setSelectedValue({ value: 'labtest', label: 'Lab Test' })
			: setSelectedValue({ value: 'all', label: 'All' });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLabTest]);

	const handelRouteToSearch = () => {
		// remove all existing quaryparams
		const params = new URLSearchParams();
		params.append('_product_type', selectedValue.value);
		params.append('_search', debouncedSearchValue);
		router.push(`/search?${params.toString()}`);
	};

	useEffect(() => {
		if (pathname.startsWith('/search')) {
			// router.push(`/search?${new URLSearchParams(rest)}`, {});
			handelRouteToSearch();
			debouncedSearchValue && updateHistory(debouncedSearchValue);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedSearchValue, selectedValue]);

	const handleFocus = () => {
		//check already in this page or not

		setIsFocused(true);
		if (pathname.startsWith('/search')) {
			return;
		}
		handelRouteToSearch();
		// router.push(`/search?${new URLSearchParams(rest)}`);
	};

	/* 	useEffect(() => {
		_product_type
			? setSelectedValue({ value: _product_type, label: _product_type })
			: setSelectedValue({ value: 'all', label: 'All' });

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [_product_type]); */

	useEffect(() => {
		if (pathname.startsWith('/lab-test') || _product_type === 'labtest') {
			setIsLabTest(true);
		} else {
			setIsLabTest(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	return (
		<div className={styles.search_warp} style={{ position: 'relative' }} ref={searchBoxRef}>
			<div
				className={cls(
					styles.top__middle__search__select,
					isFocused && styles.top__middle__search__select__focused
				)}>
				<span className={styles.top__middle__search__select__label}>{selectedValue.label}</span>
				<IC_ARROWDROPDOWN className={styles.top__middle__search__select__icon} />
				<select
					style={{}}
					onChange={(e) =>
						setSelectedValue({
							value: e.target.value || 'all',
							label: e.target.options[e.target.selectedIndex].text
						})
					}
					value={selectedValue.value}
					className={styles.top__middle__search__select__option}>
					<option value='all'>All</option>
					<option value='medicine'>Medicine</option>
					<option value='healthcare'>Healthcare</option>
					<option value='labtest'>Lab Test</option>
					<option value='beauty'>Beauty</option>
				</select>
			</div>

			<div className={styles.input_block}>
				<TypingAnimation
					id='search-input'
					value={searchValue || ''}
					onFocus={handleFocus}
					onBlur={handleBlur}
					onChange={(e: any) => {
						if(e?.target?.value?.length > 1000){
						return	toast.warning({
								message: 'Invalid Seacrh Value',
								provider: 'toast'
							})
						}else{
							setSearchValue(e.target.value);
						}
				
					}}
					readOnly={!isSearchPage}
					onKeyDown={(e: any) => {}}
				/>

				<div
					style={{
						position: 'absolute',
						right: 50
					}}>
					{searchValue && (
						<MdCancel
							size={20}
							onClick={() => {
								setSearchValue('');
							}}
							style={{
								fill: '#A0AEC0',
								cursor: 'pointer'
							}}
						/>
					)}
				</div>

				<div className={styles.top__middle__search__button}>
					{!!searchValue ? (
						<MdOutlineSearch
							onClick={() => {
								router.push(`/search?${new URLSearchParams(rest)}`);
							}}
							style={{
								width: '22px',
								height: '22px',
								fill: '#fff',
								cursor: 'pointer'
							}}
						/>
					) : (
						<MdOutlineSearch
							style={{
								width: '22px',
								height: '22px',
								fill: '#fff',
								cursor: 'pointer'
							}}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default SearchInput;

export const TypingAnimation = ({ ...rest }) => {
	const items = ['medicine products', 'healthcare products', 'beauty products', 'lab test'];
	const [currentItemIndex, setCurrentItemIndex] = useState(0);
	const [currentLetterIndex, setCurrentLetterIndex] = useState(0);
	const [isBackspacing, setIsBackspacing] = useState(false);
	const [placeholder, setPlaceholder] = useState('');

	useEffect(() => {
		const currentItem = items[currentItemIndex];

		if (!isBackspacing && currentLetterIndex < currentItem.length) {
			setTimeout(() => {
				setCurrentLetterIndex((prevIndex) => prevIndex + 1);
			}, 200);
		} else if (!isBackspacing && currentLetterIndex === currentItem.length) {
			setTimeout(() => {
				setIsBackspacing(true);
			}, 2000); // Wait 2 seconds before starting to backspace
		} else if (isBackspacing && currentLetterIndex > 0) {
			setTimeout(() => {
				setCurrentLetterIndex((prevIndex) => prevIndex - 1);
			}, 100);
		} else if (isBackspacing && currentLetterIndex === 0) {
			setIsBackspacing(false);
			setCurrentItemIndex((prevIndex) => (prevIndex + 1) % items.length);
		}

		setPlaceholder(items[currentItemIndex].substring(0, currentLetterIndex));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentLetterIndex, isBackspacing, currentItemIndex]);

	return (
		<input
			type='text'
			className={styles.top__middle__search__input}
			{...rest}
			placeholder={`Search for ${placeholder}`}
		/>
	);
};
