import React from 'react';
import Card from '@/components/atoms/Card';
import { MdInfo } from 'react-icons/md';

const OrderSummary = ({ data }: any) => {
	return (
		<Card bordered={false} bodyStyle={{}} title='Order Summary' size='small'>
			<div className='mx-5' style={{ borderBottom: '1px solid #F1F2F4' }}>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						width: '100%'
					}}>
					<p style={{ margin: 0 }}>Subtotal (MRP)</p>
					<p style={{ margin: 0, textAlign: 'right' }}>৳{data?.c_total_mrp}</p>
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						marginTop: '5px',
						width: '100%'
					}}>
					<p style={{ margin: 0 }}>Discount Applied</p>
					<p style={{ margin: 0, color: '#FD6A6A', textAlign: 'right' }}>-৳{data?.deductions_amount}</p>
				</div>
				{data?.deductions?.coupon_discount?.amount > 0 && (
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							marginTop: '5px',
							width: '100%'
						}}>
						<p style={{ margin: 0 }}>{data?.deductions?.coupon_discount?.text}</p>
						<p style={{ margin: 0, color: '#FD6A6A', textAlign: 'right' }}>
							-৳{data?.deductions?.coupon_discount?.amount}
						</p>
					</div>
				)}

				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						marginTop: '5px',
						width: '100%'
					}}>
					<p style={{ margin: 0 }}>Rounding Off</p>
					<p style={{ margin: 0, color: '#FD6A6A', textAlign: 'right' }}>-৳{data?.po_rounding_amount}</p>
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						marginTop: '5px',
						marginBottom: '10px',
						width: '100%'
					}}>
					<p style={{ margin: 0 }}>Regular Delivery</p>
					<p style={{ margin: 0, textAlign: 'right' }}>+৳{data?.po_delivery_fee}</p>
				</div>
			</div>
			<div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						marginBottom: '10px',
						width: '100%',
						marginTop: '10px'
					}}>
					<p className='mx-5' style={{ fontSize: '16px', fontWeight: 600 }}>
						Total:
					</p>
					<p className='mx-5' style={{ fontSize: '16px', fontWeight: 600, textAlign: 'right', color: '#0E7673' }}>
						৳{data?.po_payable_total}
					</p>
				</div>
				<p className='bg-info p-6 rounded-4 d-flex gap-6 items-center' style={{ width: '80%' }}>
					<MdInfo
						style={{
							color: '#2970FF'
						}}
					/>
					<span
						className='text-grey900 
										fw-500 text-10
										'>
						{/* {info} */}
						Free delivery above ৳499 taka Order.
					</span>
				</p>
			</div>
		</Card>
	);
};

export default OrderSummary;
