import React from 'react';
import cls from 'classnames';

const Descriptions = (props) => {
	const { clssName, title, value, valueClassName, titleClassName, className } = props;
	
	return (
		<div className={cls('d-flex justify-space-between', className)}>
			<div className={cls(titleClassName)}>{title}</div>
			<div className={cls(valueClassName)}>{value}</div>
		</div>
	);
};

export default Descriptions;
