'use client';
import { IM_PAYMENTS_ICONS } from '@/assets/images';
import Button from '@/components/atoms/Button';
import Card from '@/components/atoms/Card';
import CheckBox from '@/components/atoms/CheckBox';
import Input from '@/components/atoms/Input';
import Modal from '@/components/atoms/Modal';
import Radio from '@/components/atoms/Radio';
import SpinnerWrapper from '@/components/atoms/SpinnerWrapper';
import { useAlert } from '@/contexts/AlertProvider';
import useAuth from '@/contexts/AuthProvider';
import useCart from '@/contexts/CartProvider';
import AroggaAPI from '@/services/apis/AroggaAPI';
import AroggaSWR from '@/services/apis/AroggaSWR';
import { API_CART } from '@/services/apis/apiConstrant';
import usePixalEvent from '@/services/event/usePixalEvent';
import { generateQuantityOptions, newWindowLoad } from '@/services/utils/helper';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { MdHomeFilled } from 'react-icons/md';
import { get, isEmpty, map } from 'lodash';
import { IC_DELETE } from '@/assets/icons';
import OrderSummary from '../OrderSummary/OrderSummary';
import CustomToast from '../CustomToast/CustomToast';
import { StockProductDecreasedModal } from '../StockProductDecreasedModal/StockProductDecreasedModal';
import CartItem from '@/components/pageComponents/cartItems/CartItems';
import styles from './CheckoutModal.module.scss';
const CheckoutModal = ({
	isOpen,
	onClose,
	carts,
	setIsOpen,
	scheduleId,
	deliveryOption,
	hasQuantityDecreased,
	setHasQuantityDecreased,
	userType,
	handleOpenNotFound,
	setShowCheckoutModal
}: any) => {
	const router = useRouter();
	const [isOrderPlaceing, setIsOrderPlaced] = useState(false);
	const {
		setCarts,
		setDeliveryAddress,
		setDrawerOpen,
		setIsPayementLoading,
		getCart,
		removeCart,
		cartAction,
		toggleDrawer,
		drawerOpen,
		hasQuantityDecreasedModal,
		setHasQuantityDecreasedModal
	} = useCart();
	const [loading, setLoading] = useState(false);
	const [paymentMethod, setPaymentMethod] = useState(null);
	const [paymentData, setPaymentData] = useState(null);
	const [subscription, setSubscription] = useState(false);
	const [note, setNote] = useState('');
	const [visible, setVisible] = useState(false);
	const [showInDom, setShowInDom] = useState(false);
	const toast = useAlert();
	const alert = useAlert();

	const stockDecreasedProduct = carts?.products?.filter((item: any) => item?.qty_decrease_message?.length > 0)?.length
		? carts?.products?.filter((item: any) => item?.qty_decrease_message?.length > 0)
		: [];
	const stockUnavailableProduct = carts?.unavailable_items?.length > 0 ? carts?.unavailable_items : [];
	const restoredProduct = carts?.restored_items?.length > 0 ? carts?.restored_items : [];
	const customToastMessage =
		paymentData?.po_item_count === 0
			? 'Your selected all products have been out of stock'
			: paymentData?.has_qty_restored
				? 'The stock of some products has been restocked'
				: 'The stock of some products has decreased';

	const { getUserProfile, user } = useAuth();

	const {
		data,
		isLoading,
		mutate,
		status,
		message = ''
	} = AroggaSWR(`${API_CART.CHECKOUT_INIT}?_delivery_option=${deliveryOption}`);

	const { EventBeginCheckout, EventPurchase } = usePixalEvent();

	const handlePlaceOrder = async () => {
		setIsOrderPlaced(true);
		setLoading(true);
	

		const o_data = {
			po_payment_method: paymentMethod,
			po_delivery_option: deliveryOption,
			po_delivery_shift_schedule_id: scheduleId,
			po_subscription_frequency: subscription ? 'MONTHLY' : null,
			po_user_notes: note || null
		};

		const { data, status, message }: any = await AroggaAPI.post(API_CART.ORDER_CREATE_FROM_CART, o_data);

		if (data?.po_b2b_min_order_value_message?.length > 0 && userType === 'b2b') {
			alert.warning({
				title: data?.po_b2b_min_order_value_message,
				message: '',
				confirmText: 'Ok',
				onConfirm: () => {
					setIsOpen(false);
					setLoading(false);
					setHasQuantityDecreased(false);
					setDrawerOpen(true);
					setTimeout(() => {
						getCart();
					}, 1000);
					return;
				},
			});

			return;
		}

		if (data?.prescription_req || data?.has_coupon_expired) {
			let message = '';
			let subtitle = '';
			let shouldReturn = false;
			let canOrder = false;

			if (data?.prescription_req && data?.has_coupon_expired) {
				message = 'Prescription is required and your coupon has expired!';
				subtitle = 'Do you want to add a prescription and get a new coupon?';
				shouldReturn = true;
			} else if (data?.prescription_req) {
				message = 'Prescription is required!';
				shouldReturn = true;
				// subtitle = 'Would you like to go back to add the prescription?';
			} else if (data?.has_coupon_expired) {
				message = 'Your coupon has expired!';
				subtitle = 'Do you want to continue without coupon?';
				shouldReturn = true;
				canOrder = true;
			}

			alert.warning({
				title: message,
				message: subtitle,
				confirmText: 'Proceed',
				onConfirm: () => {
					canOrder === true && handlePlaceOrder();
					setIsOpen(false);
					setLoading(false);
					setHasQuantityDecreased(false);
					canOrder === true ? setDrawerOpen(false) : setDrawerOpen(true);
					setTimeout(() => {
						getCart();
					}, 1000);
					if (shouldReturn) {
						return;
					}
				},
				cancelText: 'Cancel',
				onCancel: () => {
					canOrder === true ? setLoading(true) : setLoading(false);
					canOrder === true ? setIsOrderPlaced(true) : setIsOrderPlaced(false);
					setIsOpen(false);
					canOrder === true ? setHasQuantityDecreased(true) : setHasQuantityDecreased(false);
					setTimeout(() => {
						getCart();
					}, 1000);
					if (shouldReturn) {
						return;
					}
				}
			});
			if (shouldReturn) {
				return;
			}
		}

		if (data?.has_qty_decreased === true || data?.has_qty_restored === true || data?.has_unavailable_items === true) {
			setIsOrderPlaced(false);
			setLoading(false);
			setCarts(data);
			setPaymentData(data);
			setShowCheckoutModal(false);
			setHasQuantityDecreased(true);
			return;
		}

		if (status === 'success') {
			setPaymentData(null);
			EventPurchase({
				currency: 'BDT',
				coupon: data?.d_code || '',
				items: data?.products?.map((item) => ({
					item_id: item?.p_id,
					quantity: item?.qty,
					item_name: item?.p_name
				})),
				value: data?.po_payable_total,
				contents: data?.products,
				address: data?.uc_address
			});
			const isOnlinePayment = paymentMethod === 'online' && data?.payment_url;
			setIsOrderPlaced(false);
			getUserProfile();
			setIsOpen(false);
			setLoading(false);
			setHasQuantityDecreased(false);
			setCarts([]);
			setPaymentData(null);
			setDeliveryAddress(data?.user_default_location);
			setDrawerOpen(false);
			alert.orderSuccess({
				title: 'Congratulations!',
				message: message,
				confirmText: 'Track Order',
				onConfirm: () => {
					router.push(`/account/orders/${data?.po_id}`);
				}
			});

			if (paymentMethod === 'online' && data?.payment_url) {
				newWindowLoad(data?.payment_url, data?.po_id, router, setIsPayementLoading, alert);
			}
		} else {
			//check message include Coupon is expired
			if (message?.includes('Coupon is expired')) {
				alert.warning({
					title: 'Warning',
					message: message || 'Something went wrong',
					confirmText: 'Proceed',
					onConfirm: () => {
						handlePlaceOrder();
					},
					cancelText: 'Cancel',
					onCancel: () => {
						setLoading(false);
						setIsOrderPlaced(false);
						setIsOpen(false);
						getCart();
					}
				});

				return;
			}
			alert.error({
				title: 'Error',
				message: message,
				confirmText: 'Go to Order',
				cancelText: 'Cancel'
			});
			setLoading(false);
			setIsOrderPlaced(false);
		}
	};

	const handleConfirmOrder = async () => {
		setIsOrderPlaced(true);
		setLoading(true);
		
		const o_data = {
			po_payment_method: paymentMethod,
			po_delivery_option: deliveryOption,
			po_delivery_shift_schedule_id: scheduleId,
			po_subscription_frequency: subscription ? 'MONTHLY' : null,
			po_user_notes: note || null
		};

		const ucd = carts?.products?.map((item: any) => ({
			ucd_variant_id: item.pv.id,
			ucd_qty: item.qty
		}));
		const te = ucd.reduce((acc, item, index) => {
			acc[`ucd[${index}][ucd_variant_id]`] = item.ucd_variant_id;
			acc[`ucd[${index}][ucd_qty]`] = item.ucd_qty;
			return acc;
		}, {});

		const body = {
			uc_total: carts?.po_payable_total,
			uc_count: carts?.po_item_count,
			...te
		};
		const mergedOrderObject = {
			...body,
			...o_data
		};


		const { data, status, message }: any = await AroggaAPI.post(API_CART.CONFIRM_ORDER, mergedOrderObject);

		if (data?.po_b2b_min_order_value_message?.length > 0 && userType === 'b2b') {
			alert.warning({
				title: data?.po_b2b_min_order_value_message,
				message: '',
				confirmText: 'Ok',
				onConfirm: () => {
					setIsOpen(false);
					setLoading(false);
					setHasQuantityDecreased(false);
					setDrawerOpen(true);
					setTimeout(() => {
						getCart();
					}, 1000);
					return;
				},
			});

			return;
		}

		if (data?.prescription_req || data?.has_coupon_expired) {
			let message = '';
			let subtitle = '';
			let shouldReturn = false;
			let canOrder = false;

			if (data?.prescription_req && data?.has_coupon_expired) {
				message = 'Prescription is required and your coupon has expired!';
				subtitle = 'Do you want to add a prescription and get a new coupon?';
				shouldReturn = true;
			} else if (data?.prescription_req) {
				message = 'Prescription is required!';
				shouldReturn = true;
				// subtitle = 'Would you like to go back to add the prescription?';
			} else if (data?.has_coupon_expired) {
				message = 'Your coupon has expired!';
				subtitle = 'Do you want to continue without coupon?';
				shouldReturn = true;
				canOrder = true;
			}

			alert.warning({
				title: message,
				message: subtitle,
				confirmText: 'Proceed',
				onConfirm: () => {
					canOrder === true && handleConfirmOrder();
					setIsOpen(false);
					setLoading(false);
					setHasQuantityDecreased(false);
					canOrder === true ? setDrawerOpen(false) : setDrawerOpen(true);
					setTimeout(() => {
						getCart();
					}, 1000);
					if (shouldReturn) {
						return;
					}
				},
				cancelText: 'Cancel',
				onCancel: () => {
					canOrder === true ? setLoading(true) : setLoading(false);
					canOrder === true ? setIsOrderPlaced(true) : setIsOrderPlaced(false);
					setIsOpen(false);
					canOrder === true ? setHasQuantityDecreased(true) : setHasQuantityDecreased(false);
					setTimeout(() => {
						getCart();
					}, 1000);
					if (shouldReturn) {
						return;
					}
				}
			});

			if (shouldReturn) {
				return;
			}
		}

		if (data?.has_qty_decreased === true || data?.has_qty_restored === true || data?.has_unavailable_items === true) {
			setIsOrderPlaced(false);
			setLoading(false);
			setCarts(data);
			setPaymentData(data);
			setShowCheckoutModal(false);
			setHasQuantityDecreased(true);
		}

		if (status === 'success') {
			setPaymentData(null);
			EventPurchase({
				currency: 'BDT',
				coupon: data?.d_code || '',
				items: data?.products?.map((item) => ({
					item_id: item?.p_id,
					quantity: item?.qty,
					item_name: item?.p_name
				})),
				value: data?.po_payable_total,
				contents: data?.products,
				address: data?.uc_address
			});
			const isOnlinePayment = paymentMethod === 'online' && data?.payment_url;
			setIsOrderPlaced(false);
			getUserProfile();
			setIsOpen(false);
			setLoading(false);
			setHasQuantityDecreased(false);
			setCarts([]);
			setPaymentData(null);
			setDeliveryAddress(data?.user_default_location);
			setDrawerOpen(false);
			alert.orderSuccess({
				title: 'Congratulations!',
				message: message,
				confirmText: 'Track Order',
				onConfirm: () => {
					router.push(`/account/orders/${data?.po_id}`);
				}
			});

			if (paymentMethod === 'online' && data?.payment_url) {
				newWindowLoad(data?.payment_url, data?.po_id, router, setIsPayementLoading, alert);
			}
		} else {
			//check message include Coupon is expired
			if (message?.includes('Coupon is expired')) {
				alert.warning({
					title: 'Warning',
					message: message || 'Something went wrong',
					confirmText: 'Proceed',
					onConfirm: () => {
						handleConfirmOrder();
					},
					cancelText: 'Cancel',
					onCancel: () => {
						setLoading(false);
						setIsOrderPlaced(false);
						setIsOpen(false);
						getCart();
					}
				});

				return;
			}
			alert.error({
				title: 'Error',
				message: message,
				confirmText: 'Go to Order',
				cancelText: 'Cancel'
			});
			setLoading(false);
			setIsOrderPlaced(false);
		}

		// }
	};

	const isCodAvailable = data?.po_is_cod_available;
	const isCodAvailableCompireMaxCod = data?.po_payable_total <= data?.maxCod;

	useEffect(() => {
		if (status == 'fail' && message?.includes('Coupon is expired')) {
			alert.warning({
				title: 'Warning',
				message: message || 'Something went wrong',
				confirmText: 'Proceed',
				onConfirm: () => {
					mutate();
				},
				cancelText: 'Cancel',
				onCancel: () => {
					setIsOpen(false);
				}
			});
		}
		if (!data?.po_is_cod_available) {
			setPaymentMethod('online');
		} else {
			setPaymentMethod('cod');
		}

		data &&
			EventBeginCheckout({
				currency: 'BDT',
				coupon: data?.d_code || '',
				items: data?.products?.map((item) => ({
					item_id: item?.p_id,
					quantity: item?.qty,
					item_name: item?.p_name
				})),
				value: data?.po_payable_total,
				contents: data?.products,
				address: data?.uc_address
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, status]);

	useEffect(() => {
		const channel = new BroadcastChannel('cart_channel');
		channel.onmessage = (event) => {
			mutate(event.data);
		};

		return () => {
			channel.close();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (
			carts?.has_qty_decreased === true ||
			carts?.has_qty_restored === true ||
			carts?.has_unavailable_items === true
		) {
			setVisible(true);
			setShowInDom(true);

			const hideTimer = setTimeout(() => {
				setVisible(false);
				setTimeout(() => {
					setShowInDom(false);
				}, 500);
			}, 10000);

			return () => {
				clearTimeout(hideTimer);
				setVisible(false);
				setShowInDom(false);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paymentData]);

	const showPamynetOption = data?.po_payable_total > 0;
	const hasProducts = get(carts, 'products.length', 0) > 0;
	const hasUnavailableProducts = get(carts, 'unavailable_items.length', 0) > 0;
	const isDhakaCity = get(carts, 'uc_address.l_district') === 'Dhaka City';

	const containerStyle: any = {
		padding: '12px 0px',
		width: '100%',
		bottom: '182px',
		zIndex: 999999,
		backgroundColor: 'transparent',
		transform: visible ? 'translateY(0)' : 'translateY(500px)', // Slide in/out
		transition: 'transform 0.5s ease-in-out' // Smooth transition
	};
	const notificationStyle: any = {
		color: 'white',
		backgroundColor: '#FE964A',
		padding: '8px 12px',
		borderRadius: '5px',
		width: '100%'
	};

	const filteredCarts = [...(carts?.products || []), ...(carts?.unavailable_items || [])].filter(Boolean);

	return (
		<>
			{hasQuantityDecreased ? (
				<Modal
					isOpen={hasQuantityDecreased}
					onClose={() => {
						onClose();
						setPaymentMethod(null);
						setSubscription(false);
						setHasQuantityDecreased(false);
						setNote('');
					}}
					footer={null}
					width={600}
					maskClosable={false}
					title='Your Cart Products'
					subTitle={
						carts?.has_qty_restored
							? 'The Stock of the following item(s) has available:'
							: 'The Stock of the following item(s) has decreased'
					}
					bodyStyle={{
						borderRadius: '15px',
						padding: '0px 20px',
						maxHeight: 'calc(100vh - 200px)',
						overflowY: 'auto'
					}}>
					{(hasProducts || hasUnavailableProducts) && (
						<div className={styles.drawer__body__items}>
							{/*CartItem 3  */}
							{filteredCarts?.map((item: any, i: any) => (
								<CartItem
									key={i}
									item={item}
									userType={userType}
									isDhakaCity={isDhakaCity}
									cartAction={cartAction}
									options={generateQuantityOptions(
										item.pv[`pv_${userType}_min_qty`],
										item.pv[`pv_${userType}_max_qty`],
										item.pv[`pu_unit_label`],
										item?.pv?.pu_multiplier,
										item.pv[`pu_base_unit_label`]
									)}
									removeIcon={<IC_DELETE onClick={() => removeCart(item?.pv?.id, item?.p_name)} />}
									cartsUcAddress={carts?.uc_address}
									currentPv={get(item, 'pv', {})}
									qty_decrease_message={item?.qty_decrease_message}
								/>
							))}
						</div>
					)}

					{carts && carts?.po_item_count !== 0 ? (
						<div className='my-10'>
							<OrderSummary data={carts} />
						</div>
					) : null}

					{/* Fixed at the bottom */}

					{hasUnavailableProducts && !hasProducts ? (
						<div
							style={{
								position: 'sticky',
								bottom: 0,
								background: '#fff',
								width: '100%',
								paddingBottom: '10px',
								zIndex: 10
							}}>
							{showInDom ? (
								<CustomToast
									containerStyle={containerStyle}
									notificationStyle={notificationStyle}
									setshowStockProductDecreasedModal={setHasQuantityDecreasedModal}
									customToastMessage={customToastMessage}
									showDetails={carts?.po_item_count}
								/>
							) : null}
							<p
								onClick={() => {
									setHasQuantityDecreasedModal(false);
									setHasQuantityDecreased(false);
									setPaymentData(null);
									setTimeout(() => {
										router.push('/');
									}, 1000);
								}}
								style={{
									backgroundColor: '#0E7673',
									color: 'white',
									padding: '12px 15px',
									width: '100%',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									borderRadius: '5px',
									marginTop: '10px',
									position: 'sticky',
									bottom: '20',
									zIndex: '1',
									cursor: 'pointer'
								}}>
								Continue Shopping
							</p>
						</div>
					) : (
						<div
							style={{
								position: 'sticky',
								bottom: 0,
								background: '#fff',
								width: '100%',
								paddingBottom: '10px',
								zIndex: 10
							}}>
							{showInDom ? (
								<CustomToast
									containerStyle={containerStyle}
									notificationStyle={notificationStyle}
									setshowStockProductDecreasedModal={setHasQuantityDecreasedModal}
									customToastMessage={customToastMessage}
									showDetails={carts?.po_item_count}
									setHasQuantityDecreased={setHasQuantityDecreased}
								/>
							) : null}


							<div>
								<Button type='primary' className='w-full' onClick={handleConfirmOrder}>
									Confirm Order: ৳{carts?.po_payable_total}
								</Button>
							</div>
						</div>
					)}
				</Modal>
			) : (
				<Modal
					isOpen={isOpen}
					onClose={() => {
						onClose();
						setPaymentMethod(null);
						setSubscription(false);
						setNote('');
					}}
					footer={null}
					width={550}
					maskClosable={false}
					title='Checkout'
					bodyStyle={{
						borderRadius: '15px',
						backgroundColor: '#F9FAFB',
						padding: '10px 20px',
						maxHeight: 'calc(100vh - 200px)',
						overflowY: 'auto'
					}}>
					<SpinnerWrapper loading={loading || isLoading}>
						<Card bordered={false} bodyStyle={{}} title='Shipping Address' size='small'>
							<div className='d-flex justify-content-center gap-10'>
								<MdHomeFilled size={24} style={{ color: '#718096' }} />
								<div className='d-flex flex-column gap-3'>
									<span>{carts?.uc_address?.ul_name}</span>
									<span>{carts?.uc_address?.ul_mobile}</span>
									<span>{carts?.uc_address?.ul_location}</span>
								</div>
							</div>

							<Input
								className={styles.input_notes}
								placeholder='Write here any additional info'
								type='textArea'
								multiline
								maxLength={200}
								rows={2}
								onChange={(e) => {
									setNote(e.target.value);
								}}
							/>
						</Card>
						<div className='pb-20'>
							<CheckBox
								inputStyle={{
									borderColor: '#CBD5E0'
								}}
								label='আমি প্রতি মাসেই কিনতে চাই'
								onChange={(e) => {
									setSubscription(e);
								}}
							/>
							{subscription && (
								<div className='pl-27 text-16'>
									আজ থেকে ২৮ দিন পরে আপনার নাম আরেকটি অর্ডার তৈরী হবে, আমাদের ফার্মাসিস্ট আপনাকে ফোন করে
									অর্ডারটি কনফার্ম করবেন
								</div>
							)}
						</div>
						<Card
							size='small'
							bordered={false}
							bodyStyle={{
								padding: '20px'
							}}
							headerStyle={{
								borderRadius: '12px 12px 0px 0px',
								backgroundColor: '#E5F6F5'
							}}>
							{showPamynetOption ? (
								<div className='d-flex flex-column gap-20'>
									<Radio.Group
										name='address'
										style={{
											display: 'flex',
											flexDirection: 'column',
											gap: 20
										}}
										defaultValue={paymentMethod}
										onChange={(e) => {
											const selectedValue = e.target.value;
											if (!isCodAvailable && selectedValue === 'cod') {
												alert.warning({
													title: 'Warning',
													message: 'COD is not available'
												});
												setPaymentMethod('online');
											} else {
												setPaymentMethod(e.target.value);
											}
										}}>
										<Radio
											value='cod'
											disabled={!isCodAvailable}
											onClick={() => {
												const selectedValue = 'cod';
												if (!isCodAvailable && selectedValue === 'cod') {
													alert.warning({
														title: 'Warning',
														message: 'COD is not available'
													});
													setPaymentMethod('online');
												} else {
													setPaymentMethod('cod');
												}
											}}
											style={
												!isCodAvailable
													? {
															cursor: 'not-allowed'
														}
													: {}
											}>
											<span
												className='text-grey900 fw-700'
												style={isCodAvailable ? { cursor: 'pointer' } : { cursor: 'not-allowed' }}
												onClick={() => {
													if (!isCodAvailable) {
														alert.warning({
															title: 'Warning',
															message: 'COD is not available'
														});
													}
												}}>
												{isCodAvailable ? (
													'Cash on delivery'
												) : (
													<>
														<div className='text-grey500'>Cash on delivery</div>
														{!isCodAvailableCompireMaxCod && (
															<div className=' text-14 fw-400 text-grey500'>
																Maximun amount for COD is ৳ {data?.maxCod}
															</div>
														)}
													</>
												)}
											</span>
										</Radio>
										<Radio
											value='online'
											onClick={() => {
												setPaymentMethod('online');
											}}></Radio>
										<div
											style={{
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												marginTop: -40,
												marginLeft: 20
											}}>
											<Image
												src={IM_PAYMENTS_ICONS.src}
												width={413}
												height={150}
												alt='payment icons'
												unoptimized
											/>
										</div>
									</Radio.Group>
								</div>
							) : (
								<div className='d-flex flex-column gap-20 fw-500'>
									{user.u_cash_balance > 0 ? (
										<span>Your Payment will be deducted from your Arogga Cash Balance</span>
									) : (
										<div className='text-14 text-grey500'>
											Our customer service will call you to confirm your order and payment amount
										</div>
									)}
								</div>
							)}

							{paymentMethod === 'online' && (
								<>
									<div className='p-20 bg-primary_bg rounded-10 mt-10 text-grey-600 text-14'>
										<FaInfoCircle />{' '}
										<span className='ml-10'>
											<span>
												{data?.should_open_popup_for_online_payment
													? 'A payment window will appear after placing order.'
													: 'You will be given an option to pay online after order is packed and ready for delivery.'}
											</span>
										</span>
									</div>
								</>
							)}
							{!isEmpty(data?.note) && (
								<>
									<div className='p-20 bg-error_bg rounded-10 mt-10 text-grey-600 text-14'>
										<FaInfoCircle
											style={{
												color: 'red'
											}}
										/>{' '}
										<span className='ml-10'>
											<span>{data.note}</span>
										</span>
									</div>
								</>
							)}
						</Card>

						{carts?.po_item_count !== 0 && <OrderSummary data={carts} />}

						<p
							className='text-grey600 mb-5'
							style={{
								fontSize: 14,
								lineHeight: '160%'
							}}>
							By continuing you agree to{' '}
							<a href='/page/tos' target='_blank' className='text-primary fw-500'>
								Terms &amp; Conditions
							</a>
							{','}
							<a href='/page/privacy' target='_blank' className='text-primary fw-500'>
								Privacy Policy
							</a>{' '}
							&amp;{' '}
							<a href='/page/return-policy' target='_blank' className='text-primary fw-500'>
								Refund-Return Policy
							</a>
						</p>

						<div
							style={{
								position: 'sticky',
								bottom: 0,
								background: '#fff',
								width: '100%',
								zIndex: 10
							}}>
							<div>
								<Button disabled={isOrderPlaceing} type='primary' className='w-full' onClick={handlePlaceOrder}>
									Place Order: ৳{carts?.po_payable_total}
								</Button>
							</div>
						</div>
					</SpinnerWrapper>
				</Modal>
			)}

			{/* Stock Change deacrese  Modal*/}
			{(stockDecreasedProduct.length > 0 || stockUnavailableProduct.length > 0 || restoredProduct.length > 0) && (
				<StockProductDecreasedModal
					stockDecreasedProduct={
						stockUnavailableProduct?.length > 0 || restoredProduct?.length > 0
							? [...stockUnavailableProduct, ...stockDecreasedProduct, ...restoredProduct]
							: stockDecreasedProduct
					}
					showStockProductDecreasedModal={hasQuantityDecreasedModal}
					setshowStockProductDecreasedModal={setHasQuantityDecreasedModal}
					title={carts?.has_qty_restored ? 'Stock available' : 'Stock decrease'}
					subTitle={
						carts?.has_qty_restored
							? 'The Stock of following item(s) has available:'
							: 'The Stock of following item(s) has decreased:'
					}
					setHasQuantityDecreased={setHasQuantityDecreased}
				/>
			)}
		</>
	);
};

export default CheckoutModal;
