import Modal from '@/components/atoms/Modal';
import { generateProductLink, getProductImage, renderLabel } from '@/services/utils/helper';
import Image from 'next/image';
import Link from 'next/link';
import { Fragment } from 'react';
import styles from './StockProductDecreasedModal.module.scss';
import Tooltip from '@/components/atoms/Tooltip/Tooltip';


export const StockProductDecreasedModal = ({
	stockDecreasedProduct,
	showStockProductDecreasedModal,
	setshowStockProductDecreasedModal,
	title,
	subTitle,
	setHasQuantityDecreased
}:any) => {

	return (
		<Modal
			isOpen={showStockProductDecreasedModal}
			onClose={() => {
				setshowStockProductDecreasedModal(false);
				setHasQuantityDecreased && setHasQuantityDecreased(true)
			}}
			footer={null}
			width={600}
			maskClosable={false}
			title={title}
			subTitle={subTitle}
			bodyStyle={{
				borderRadius: '15px',
				padding: '0px 20px',
				maxHeight: 'calc(100vh - 200px)',
				overflowY: 'auto'
			}}>
			{stockDecreasedProduct && (
				<div className={styles.drawer__body__items}>
					{stockDecreasedProduct?.map((item: any, i: any) => {
						return (
							<Fragment key={i}>
								<div className={styles.items__item}>
									<div className='d-flex w-full'>
										<div
											className={styles.items__left}
											style={{
												width: '100%'
											}}>
											<div className={styles.item__image}>
												<Link href={generateProductLink(item, item.pv)}>
													<Image
														width={60}
														height={60}
														unoptimized={true}
														src={getProductImage(item)}
														alt={item.p_name}
														className={styles.cardImage}
														style={{
															objectFit: 'cover'
														}}
													/>
												</Link>
											</div>
											<div className={styles.item__details}>
												<div className={styles.item_name}>
													<Link href={generateProductLink(item, item.pv)}>
														<Tooltip content={item.p_name} size='small'>
															<span className={styles.item_name}>{item.p_name}</span>
														</Tooltip>
													</Link>
												</div>
												{item.p_strength && (
													<div
														className='text-12 text-grey500 fw-400'
														style={{
															whiteSpace: 'nowrap',
															overflow: 'hidden',
															textOverflow: 'ellipsis',
															width: '90%'
														}}>
														{/* <Tooltip
															content={`${item.p_strength} ${item.p_form && ` - ${item.p_form}`}`}
															size='small'> */}
															{item.p_strength} {item.p_form && ` - ${item.p_form}`}
														{/* </Tooltip> */}
													</div>
												)}
											</div>
										</div>
									</div>
									<p className='mt-0' style={{ color: '#718096', fontSize: '14px', fontWeight: '400' }}>
										QTY :{' '}
										<span style={{ color: '#FE964A', fontSize: '14px', fontWeight: '400' }}>
											{item?.poi_stocks}
										</span>
									</p>
								</div>
							</Fragment>
						);
					})}
				</div>
			)}

			<div
				style={{
					position: 'sticky',
					bottom: 0,
					background: '#fff',
					width: '100%',
					paddingBottom: '10px',
					zIndex: 10
				}}>
				<p
					onClick={() => {
						setshowStockProductDecreasedModal(false);
						setHasQuantityDecreased && setHasQuantityDecreased(true)

					}}
					style={{
						backgroundColor: '#0E7673',
						color: 'white',
						padding: '12px 8px',
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						borderRadius: '5px',
						marginTop: '10px',
						position: 'sticky',
						bottom: '20',
						zIndex: '1',
						cursor: 'pointer'
					}}>
					Got it
				</p>
			</div>
		</Modal>
	);
};
