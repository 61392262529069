'use client';
import { IC_NOTIFIED } from '@/assets/icons';
import Button from '@/components/atoms/Button';
import Modal from '@/components/atoms/Modal';
import CartItem from '@/components/pageComponents/cartItems/CartItems';
import { useAlert } from '@/contexts/AlertProvider';
import useCart from '@/contexts/CartProvider';
import AroggaAPI from '@/services/apis/AroggaAPI';
import { includes } from 'lodash';
import React, { Fragment, useState } from 'react';
import { BsHeartFill } from 'react-icons/bs';
import cls from 'classnames';
import styles from './AllUnavailableItemsModal.module.scss';
import { API_CART } from '@/services/apis/apiConstrant';

const NotifyButton = ({ item }: any) => {
	const { setAllRequestedProducts, allRequestedProducts } = useCart();
	const checkIsRequested = includes(allRequestedProducts, item.pv.id);
	const toast = useAlert();

	return (
		<Button
			style={{
				position: 'absolute',
				bottom: 0,
				right: 0
			}}
			onClick={() => {
				toast.warning({
					title: 'Notify Me',
					message: 'We will notify you when this product is available.',
					confirmText: 'OK',
					onConfirm: async () => {
						try {
							const { data, status, message } = await AroggaAPI.post('/general/v1/ProductRequestStock', {
								prs_product_id: item.p_id,
								prs_product_variant_id: item.pv.id
							});
							setAllRequestedProducts([...allRequestedProducts, item.pv.id]);

							if (status === 'success') {
								toast.success({
									title: 'Success',
									message: 'As requested, you will receive notification once comes in stock',
									provider: 'toast'
								});
							} else {
								toast.error({
									message: message ? message : 'Something went wrong!',
									provider: 'toast'
								});
							}
						} catch (err) {
							toast.error({
								message: 'Something went wrong!',
								provider: 'toast'
							});
						}
					}
				});
			}}
			type='primary-outline'
			label={
				checkIsRequested ? (
					<div className='d-flex items-center gap-5'>
						<IC_NOTIFIED />
					</div>
				) : (
					<span className=''>Notify</span>
				)
			}
			disabled={checkIsRequested}
			size='small'
		/>
	);
};
const AllUnavailableItemsModal = ({
	showAllUnavailableItemsModal,
	setShowAllUnavailableItemsModal,
	unavailableItems,
	userType,
	isDhakaCity,
	cartAction
}: any) => {
	const toast = useAlert();
	const alert = useAlert();
	const { setCarts } = useCart();
	const handleRemoveUnavailableProductsFromCart = async () => {
		toast.deleteAllUnavailableProductsFromCart({
			title: 'Are you sure?',
			message: `Do you want to delete unavailable items?`,
			confirmText: 'Yes',
			cancelText: 'No',
			onConfirm: async () => {
				try {
					const { status, message, data } = await AroggaAPI.post(API_CART.REMOVE_UNAVAILABLE_PRODDUCTS_FROM_CART);
					if (status === 'fail') {
						alert.error({
							title: 'Oops !',
							message: message
						});
						return false;
					} else {
						alert.success({
							title: 'Success',
							message: 'Successfully deleted unavailable items.'
						});
						setCarts(data);
						setShowAllUnavailableItemsModal(false);

						return true;
					}
				} catch (err) {
					console.log(err);
				}
			}
		});
	};

	const toggleWishlist = async () => {
		// setReacted(!reacted);
		toast.bulkAddTofavProductsFromCart({
			title: 'Are you sure?',
			message: `Do you want to move the unavailable items to your wishlist?`,
			confirmText: 'Yes',
			cancelText: 'No',
			onConfirm: async () => {
				try {
					const { status, message, data } = await AroggaAPI.post(API_CART.ADD_ITEMS_TO_WISHLIST);
					if (status === 'fail') {
						alert.error({
							title: 'Oops !',
							message: message
						});
						return false;
					} else {
						alert.success({
							title: 'Success',
							message: 'Successfully moved to your wishlist.'
						});
						setCarts(data);
						setShowAllUnavailableItemsModal(false);
						return true;
					}
				} catch (err) {
					console.log(err);
				}
			}
		});
	};
	return (
		<Modal
			isOpen={showAllUnavailableItemsModal}
			onClose={() => {
				setShowAllUnavailableItemsModal(false);
			}}
			footer={null}
			width={600}
			maskClosable={false}
			title={`Unavailable items (${unavailableItems?.length})`}
			subTitle={'The following item(s) are not available for delivery.'}
			closeIcon={false}
			otherItems={
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						gap: '10px',
						cursor: 'pointer'
					}}>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='20'
						height='21'
						viewBox='0 0 20 21'
						fill='none'
						onClick={() => handleRemoveUnavailableProductsFromCart()}>
						<mask
							id='mask0_4746_51571'
							mask-type='alpha'
							maskUnits='userSpaceOnUse'
							x='0'
							y='0'
							width='20'
							height='21'>
							<rect y='0.884766' width='20' height='20' fill='#D9D9D9' />
						</mask>
						<g mask='url(#mask0_4746_51571)'>
							<path
								d='M5.83594 18.3848C5.3776 18.3848 4.98524 18.2216 4.65885 17.8952C4.33247 17.5688 4.16927 17.1764 4.16927 16.7181V5.88477H3.33594V4.2181H7.5026V3.38477H12.5026V4.2181H16.6693V5.88477H15.8359V16.7181C15.8359 17.1764 15.6727 17.5688 15.3464 17.8952C15.02 18.2216 14.6276 18.3848 14.1693 18.3848H5.83594ZM14.1693 5.88477H5.83594V16.7181H14.1693V5.88477ZM7.5026 15.0514H9.16927V7.55143H7.5026V15.0514ZM10.8359 15.0514H12.5026V7.55143H10.8359V15.0514Z'
								fill='#FD4F4F'
							/>
						</g>
					</svg>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='20'
						height='21'
						viewBox='0 0 20 21'
						fill='none'
						onClick={toggleWishlist}
						className='cursor-pointer'
						style={{ marginTop: '3px' }}>
						<mask
							id='mask0_24248_7302'
							style={{ maskType: 'alpha' }}
							maskUnits='userSpaceOnUse'
							x='0'
							y='0'
							width='20'
							height='21'>
							<rect y='0.884766' width='20' height='20' fill='#D9D9D9' />
						</mask>
						<g mask='url(#mask0_24248_7302)'>
							<path
								d='M10.0003 18.3847L8.79199 17.3014C7.38921 16.0375 6.22949 14.9472 5.31283 14.0305C4.39616 13.1139 3.66699 12.2909 3.12533 11.5618C2.58366 10.8326 2.20519 10.1625 1.98991 9.55135C1.77463 8.94024 1.66699 8.31524 1.66699 7.67635C1.66699 6.3708 2.10449 5.28052 2.97949 4.40552C3.85449 3.53052 4.94477 3.09302 6.25033 3.09302C6.97255 3.09302 7.66005 3.2458 8.31283 3.55135C8.9656 3.85691 9.5281 4.28746 10.0003 4.84302C10.4725 4.28746 11.035 3.85691 11.6878 3.55135C12.3406 3.2458 13.0281 3.09302 13.7503 3.09302C15.0559 3.09302 16.1462 3.53052 17.0212 4.40552C17.8962 5.28052 18.3337 6.3708 18.3337 7.67635C18.3337 8.31524 18.226 8.94024 18.0107 9.55135C17.7955 10.1625 17.417 10.8326 16.8753 11.5618C16.3337 12.2909 15.6045 13.1139 14.6878 14.0305C13.7712 14.9472 12.6114 16.0375 11.2087 17.3014L10.0003 18.3847ZM10.0003 16.1347C11.3337 14.9402 12.4309 13.9159 13.292 13.0618C14.1531 12.2076 14.8337 11.4645 15.3337 10.8326C15.8337 10.2007 16.1809 9.63816 16.3753 9.1451C16.5698 8.65205 16.667 8.16246 16.667 7.67635C16.667 6.84302 16.3892 6.14857 15.8337 5.59302C15.2781 5.03746 14.5837 4.75968 13.7503 4.75968C13.0975 4.75968 12.4934 4.94371 11.9378 5.31177C11.3823 5.67982 11.0003 6.14857 10.792 6.71802H9.20866C9.00033 6.14857 8.61838 5.67982 8.06283 5.31177C7.50727 4.94371 6.9031 4.75968 6.25033 4.75968C5.41699 4.75968 4.72255 5.03746 4.16699 5.59302C3.61144 6.14857 3.33366 6.84302 3.33366 7.67635C3.33366 8.16246 3.43088 8.65205 3.62533 9.1451C3.81977 9.63816 4.16699 10.2007 4.66699 10.8326C5.16699 11.4645 5.84755 12.2076 6.70866 13.0618C7.56977 13.9159 8.66699 14.9402 10.0003 16.1347Z'
								fill='#FD4F4F'
							/>
						</g>
					</svg>
				</div>
			}
			bodyStyle={{
				borderRadius: '15px',
				padding: '0px 20px',
				maxHeight: 'calc(100vh - 200px)',
				overflowY: 'auto'
			}}>
			{unavailableItems?.length > 0 && (
				<div className={styles.drawer__body__items}>
					{unavailableItems?.map((item: any, i: any) => (
						<CartItem
							key={i}
							item={item}
							userType={userType}
							isDhakaCity={isDhakaCity}
							cartAction={cartAction}
							showItemsRight={false}
							ButtonComponent={!isDhakaCity && item?.p_cold ? <Fragment /> : <NotifyButton item={item} />}
							showNotify={true}
						/>
					))}
				</div>
			)}
			<div
				style={{
					position: 'sticky',
					bottom: 0,
					background: '#fff',
					width: '100%',
					paddingBottom: '10px',
					zIndex: 10
				}}>
				<p
					onClick={() => {
						setShowAllUnavailableItemsModal(false);
					}}
					style={{
						backgroundColor: '#0E7673',
						color: 'white',
						padding: '12px 8px',
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						borderRadius: '5px',
						marginTop: '10px',
						position: 'sticky',
						bottom: '20',
						zIndex: '1',
						cursor: 'pointer'
					}}>
					Got it
				</p>
			</div>
		</Modal>
	);
};

export default AllUnavailableItemsModal;
