'use client';
import SpinnerWrapper from '@/components/atoms/SpinnerWrapper';
import useAuth from '@/contexts/AuthProvider';
import useCart from '@/contexts/CartProvider';
import useLoginModel from '@/contexts/LoginModelProvider';
import useStickyCartContext from '@/contexts/StickyCartProvider';
import useAtTop from '@/services/hooks/useAtTop';
import useLabCart from '@labServices/context/CartContext';
import useToggle from '@labServices/hooks/use-toggle';
import OrderSuccessModal from '@labtest/presentation//organisms/OrderSuccessModal';
import StateFullModal from '@labtest/presentation/atoms/Modal/StateFullModal';
import LabTestCheckoutSummary from '@labtest/presentation/molecules/LabTestCheckoutSummary';
import ModalHeader from '@labtest/presentation/molecules/ModalHeader';
import Cart from '@labtest/presentation/organisms/Cart';
import Checkout from '@labtest/presentation/organisms/Checkout';
import PopUpModal from '@labtest/presentation/organisms/PopUpModal';
import dynamic from 'next/dynamic';
import AddressModal from '../AddressModal';
import CheckoutModal from '../CheckoutModal';
import { useAuthContext } from '@labServices/context';
import { get, size } from 'lodash';
import { usePathname } from 'next/navigation';
import { Suspense, useEffect, useRef, useState } from 'react';
import { IoMdCart } from 'react-icons/io';
import { MdClose } from 'react-icons/md';
import MedicineCart, { MedicineCheckOutSummary } from './MedicineCart';
import useOutsideClick from '@labServices/hooks/useOutsideClick';
import cls from 'classnames';
import styles from './sticky-cart.module.scss';
import { StockProductDecreasedModal } from '../StockProductDecreasedModal/StockProductDecreasedModal';
import CustomToast from '../CustomToast/CustomToast';

const Odometer = dynamic(() => import('react-odometerjs'), {
	ssr: false,
	loading: () => <span>0</span>
});

const StickyCart = () => {
	const {
		carts,
		loadingState,
		toggleDrawer,
		setDrawerOpen,
		drawerOpen,
		showAddressModal,
		setShowAddressModal,
		hasQuantityDecreased,
		setHasQuantityDecreased,
		getCart,
		hasQuantityDecreasedModal,
		setHasQuantityDecreasedModal
	} = useCart();
	const isTop = useAtTop();
	const { isLogged, isB2B, loading }: any = useAuth();
	const { setShowLoginModal } = useLoginModel();
	const [scheduleId, setScheduleId] = useState<any>(null);
	const [showCheckoutModal, setShowCheckoutModal] = useState(false);
	const [deliveryOption, setDeliveryOption] = useState(carts?.po_delivery_option);
	const [prescriptionLoading, setPrescriptionLoading] = useState(false);
	const [isLabCart, setIsLabCart] = useState(false);
	const userType = isB2B ? 'b2b' : 'b2c';
	const pathname = usePathname();
	const { stickyCartDetails } = useStickyCartContext();
	const [visible, setVisible] = useState(false);
	const [showInDom, setShowInDom] = useState(false);
	const [showStockProductDecreasedModal, setshowStockProductDecreasedModal] = useState(false);
	const stockDecreasedProduct =
		carts?.products?.length > 0 &&
		carts?.products?.filter((item: any) => item?.qty_decrease_message?.length > 0)?.length
			? carts?.products?.filter((item: any) => item?.qty_decrease_message?.length > 0)
			: [];
	const stockUnavailableProduct =
		carts?.unavailable_items?.length > 0 && carts?.unavailable_items?.length > 0 ? carts?.unavailable_items : [];
	const restoredProduct = carts?.restored_items && carts?.restored_items?.length > 0 ? carts?.restored_items : [];
	// Lab-test
	const { showCheckoutModal: showCheckoutModal1, setShowCheckoutModal: setShowCheckoutModal1 } = useAuthContext();
	const [openCheckout, showModal, closeModal] = useToggle();
	const [openNotfound, handleOpenNotFound, handleCloseNotfound] = useToggle();
	const [openOrderSuccessModal, handleOpenOrderSuccessModal, handleCloseOrderSuccessModal] = useToggle();
	const [notAvailableRes, setNotAvailableRes] = useState(null);
	const [labCheckoutData, setLabCheckoutData] = useState({});
	const [orderDetails, setOrderDetails] = useState({});
	const { cartData } = useLabCart();
	const ref = useRef(null);
	const customToastMessage = carts?.has_qty_restored
		? 'The stock of some products has restock'
		: 'The stock of some products has decreased';

	useOutsideClick(ref, () => {
		setDrawerOpen(false);
	});

	const handleCloseDrawer = () => {
		setShowCheckoutModal1 && setShowCheckoutModal1(false);
	};

	useEffect(() => {
		setDeliveryOption(carts?.po_delivery_option);
	}, [carts?.po_delivery_option]);

	useEffect(() => {
		if (pathname.startsWith('/lab-test')) {
			setIsLabCart(true);
		} else {
			setIsLabCart(false);
		}
	}, [pathname]);

	useEffect(() => {
		if (carts?.has_qty_decreased === true || carts?.has_qty_restored === true) {
			setVisible(true);
			setShowInDom(true);
			const hideTimer = setTimeout(() => {
				setVisible(false);
				setTimeout(() => {
					setShowInDom(false);
				}, 500);
			}, 10000);

			return () => {
				clearTimeout(hideTimer);
				setVisible(false);
				setShowInDom(false);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [carts?.products]);

	const containerStyle: any = {
		padding: '12px 12px',
		position: 'fixed',
		bottom: '82px',
		zIndex: 999999,
		marginLeft: '5px',
		backgroundColor: 'transparent',
		transform: visible ? 'translateY(0)' : 'translateY(500px)',
		transition: 'transform 0.5s ease-in-out'
	};
	const notificationStyle: any = {
		color: 'white',
		backgroundColor: '#FE964A',
		padding: '12px 12px',
		borderRadius: '5px',
		width: '343px'
	};

	return (
		<div className={styles.sticky__cart}>
			<div
				className={`${styles.sticky__box} sticky__cart`}
				style={{ cursor: loading ? 'not-allowed' : 'pointer' }}
				onClick={() => {
					toggleDrawer();
					setTimeout(() => {
						getCart();
					}, 1000);
				}}>
				<div className={styles.sticky__box__top}>
					<IoMdCart size={24} />
					<span>{!loadingState && isLogged && stickyCartDetails.count} Items</span>
				</div>
				<div className={styles.sticky__box__bottom}>
					<span className='text-12'>৳</span>
					<Suspense fallback={<span>0</span>}>
						<Odometer value={get(stickyCartDetails, 'total', '0')} format='(,ddd).dd' />
					</Suspense>
				</div>
			</div>
			{isLogged && showAddressModal && (
				<AddressModal isOpen={showAddressModal} setIsOpen={setShowAddressModal} hover={true} parent='drawer' />
			)}
			{isLogged && (
				<div ref={ref} className={`${styles.sticky__drawer} ${drawerOpen ? styles.open : ''}`}>
					<div className={styles.drawer__header}>
						<div className={styles.drawer__header__title}>
							<div className='text-16 text-grey900 fw-600'>Shopping Cart</div>
						</div>
						<div className={styles.drawer__header__close} onClick={toggleDrawer}>
							<MdClose className={styles.drawer__header__close__icon} size={24} />
						</div>
					</div>
					<div
						className={styles.drawer__body}
						style={{
							backgroundColor: '#fff'
						}}>
						<SpinnerWrapper loading={loadingState || prescriptionLoading}>
							<div
								className='text-14 text-grey900 fw-600 mb-10 border1-grey300 '
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									boxShadow: '0px 2px 15px 0px rgba(0, 0, 0, 0.1)',
									padding: '6px 6px',
									borderRadius: '50px'
								}}>
								<div
									className={cls('py-7 px-10  cursor-pointer', {
										'bg-primary': !isLabCart,
										'text-white': !isLabCart,
										'text-black': isLabCart
									})}
									onClick={() => {
										setIsLabCart(false);
									}}
									style={{
										width: '60%',
										position: 'relative',
										display: 'flex',
										gap: '5px',
										justifyContent: 'center',
										alignItems: 'center',
										borderRadius: '50px',
										fontSize: 14,
										fontWeight: 500
									}}>
									Products
									{!!size(carts?.products) && (
										<span
											style={{
												display: 'inline-flex',
												backgroundColor: isLabCart ? '#FD6A6A' : '#fff',
												color: isLabCart ? '#fff' : '#FD6A6A',
												borderRadius: '50%',
												height: '20px',
												width: '20px',
												padding: '2px',
												justifyContent: 'center',
												alignItems: 'center',
												fontSize: '10px'
											}}>
											{get(carts, 'products.length', '0')}
										</span>
									)}
								</div>
								<div
									className={cls('p-7 px-10 rounded-50 cursor-pointer', {
										'bg-primary': isLabCart,
										'text-white': isLabCart,
										'text-black': !isLabCart
									})}
									onClick={() => {
										setIsLabCart(true);
									}}
									style={{
										width: '50%',
										display: 'flex',
										justifyContent: 'center',
										borderRadius: '50px',
										fontSize: 14,
										fontWeight: 500
									}}>
									Lab Tests
									{cartData?.itemCount > 0 && (
										<span
											style={{
												display: 'inline-flex',
												backgroundColor: isLabCart ? '#fff' : '#FD6A6A',
												color: isLabCart ? '#FD6A6A' : '#fff',
												borderRadius: '50%',
												height: '20px',
												width: '20px',
												padding: '2px',
												justifyContent: 'center',
												alignItems: 'center',
												fontSize: '10px',
												marginLeft: '5px'
											}}>
											{cartData?.itemCount}
										</span>
									)}
								</div>
							</div>

							{isLabCart ? (
								<>
									<Cart closeCart={handleCloseDrawer} />

									<StateFullModal open={openCheckout} closeModal={closeModal}>
										<ModalHeader title='Checkout' closeModal={closeModal} />
										{openCheckout && (
											<Checkout
												labCheckoutData={labCheckoutData}
												closeCheckoutModal={closeModal}
												handleOpenNotFound={handleOpenNotFound}
												handleOpenOrderSuccessModal={handleOpenOrderSuccessModal}
												setOrderDetails={setOrderDetails}
												setNotAvailableRes={setNotAvailableRes}
											/>
										)}
									</StateFullModal>

									{openNotfound && (
										<StateFullModal open={openNotfound} closeModal={handleCloseNotfound}>
											<PopUpModal
												modalType={`not_available`}
												title={`Sorry`}
												errorTag={notAvailableRes?.data?.errorTag}
												message={notAvailableRes?.message}
												cancelDelete={handleCloseNotfound}
												confirmDelete={handleCloseNotfound}
											/>
										</StateFullModal>
									)}

									<StateFullModal open={openOrderSuccessModal} closeModal={handleCloseOrderSuccessModal}>
										<ModalHeader name={`order_success_header`} closeModal={handleCloseOrderSuccessModal} />
										<OrderSuccessModal
											data={orderDetails}
											title={`Congratulations!`}
											message={`Your Booking Placed Successfully`}
											closeOrderSuccessModal={handleCloseOrderSuccessModal}
											closeCheckoutModal={closeModal}
										/>
									</StateFullModal>
								</>
							) : (
								<MedicineCart
									styles={styles}
									userType={userType}
									setPrescriptionLoading={setPrescriptionLoading}
									setScheduleId={setScheduleId}
									deliveryOption={deliveryOption}
									setDeliveryOption={setDeliveryOption}
								/>
							)}
						</SpinnerWrapper>
					</div>

					{showInDom ? (
						<CustomToast
							containerStyle={containerStyle}
							notificationStyle={notificationStyle}
							setshowStockProductDecreasedModal={setshowStockProductDecreasedModal}
							customToastMessage={customToastMessage}
							showDetails={carts?.po_item_count}
						/>
					) : null}

					{/* Checkout Summaray */}

					{isLabCart ? (
						<LabTestCheckoutSummary
							isLogged={isLogged}
							showCheckoutModal={showModal}
							handleOpenNotFound={handleOpenNotFound}
							setLabCheckoutData={setLabCheckoutData}
							setNotAvailableRes={setNotAvailableRes}
							setShowLoginModal={setShowLoginModal}
							setShowCheckoutModal={setShowCheckoutModal}
							setShowAddressModal={setShowAddressModal}
						/>
					) : (
						<MedicineCheckOutSummary
							isLogged={isLogged}
							styles={styles}
							setShowLoginModal={setShowLoginModal}
							setShowCheckoutModal={setShowCheckoutModal}
							hasQuantityDecreased={hasQuantityDecreased}
							setHasQuantityDecreased={setHasQuantityDecreased}
							deliveryOption={deliveryOption}
							userType={userType}
						/>
					)}
				</div>
			)}
			{isLogged && (showCheckoutModal || hasQuantityDecreased || hasQuantityDecreasedModal) && !isLabCart && (
				<CheckoutModal
					isOpen={showCheckoutModal}
					setIsOpen={setShowCheckoutModal}
					hasQuantityDecreased={hasQuantityDecreased}
					setHasQuantityDecreased={setHasQuantityDecreased}
					carts={carts}
					scheduleId={scheduleId}
					deliveryOption={deliveryOption}
					userType={userType}
					setShowCheckoutModal={setShowCheckoutModal}
					onClose={() => {
						setShowCheckoutModal(false);
					}}
				/>
			)}

			{/* Stock Change deacrese  Modal*/}
			{(stockDecreasedProduct.length > 0 || stockUnavailableProduct.length > 0 || restoredProduct.length > 0) && (
				<StockProductDecreasedModal
					stockDecreasedProduct={
						stockUnavailableProduct?.length > 0 || restoredProduct?.length > 0
							? [...stockUnavailableProduct, ...stockDecreasedProduct, ...restoredProduct]
							: stockDecreasedProduct
					}
					showStockProductDecreasedModal={showStockProductDecreasedModal}
					setshowStockProductDecreasedModal={setshowStockProductDecreasedModal}
					title={carts?.has_qty_restored ? 'Stock available' : 'Stock decrease'}
					subTitle={
						carts?.has_qty_restored
							? 'The Stock of following item(s) has available:'
							: 'The Stock of following item(s) has decreased:'
					}
				/>
			)}

			{/* TODO:: Lab Checkout Modal */}
		</div>
	);
};

export default StickyCart;
