'use client';
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CustomToast = ({
	containerStyle,
	notificationStyle,
	setshowStockProductDecreasedModal,
	customToastMessage,
	showDetails,
	setHasQuantityDecreased
}:any) => {
	return (
		<>
			<div style={containerStyle}>
				<div style={notificationStyle}>
					<div style={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
						<div style={{ display: 'flex', gap: '5px' }}>
							<div>
								<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
									<g id='Info Icon'>
										<path
											id='vector'
											d='M9.16406 14.1666H10.8307V9.16663H9.16406V14.1666ZM9.9974 7.49996C10.2335 7.49996 10.4316 7.41996 10.5916 7.25996C10.751 7.10051 10.8307 6.90274 10.8307 6.66663C10.8307 6.43052 10.751 6.23246 10.5916 6.07246C10.4316 5.91302 10.2335 5.83329 9.9974 5.83329C9.76128 5.83329 9.56351 5.91302 9.40406 6.07246C9.24406 6.23246 9.16406 6.43052 9.16406 6.66663C9.16406 6.90274 9.24406 7.10051 9.40406 7.25996C9.56351 7.41996 9.76128 7.49996 9.9974 7.49996ZM9.9974 18.3333C8.84462 18.3333 7.76128 18.1144 6.7474 17.6766C5.73351 17.2394 4.85156 16.6458 4.10156 15.8958C3.35156 15.1458 2.75795 14.2638 2.32073 13.25C1.88295 12.2361 1.66406 11.1527 1.66406 9.99996C1.66406 8.84718 1.88295 7.76385 2.32073 6.74996C2.75795 5.73607 3.35156 4.85413 4.10156 4.10413C4.85156 3.35413 5.73351 2.76024 6.7474 2.32246C7.76128 1.88524 8.84462 1.66663 9.9974 1.66663C11.1502 1.66663 12.2335 1.88524 13.2474 2.32246C14.2613 2.76024 15.1432 3.35413 15.8932 4.10413C16.6432 4.85413 17.2368 5.73607 17.6741 6.74996C18.1118 7.76385 18.3307 8.84718 18.3307 9.99996C18.3307 11.1527 18.1118 12.2361 17.6741 13.25C17.2368 14.2638 16.6432 15.1458 15.8932 15.8958C15.1432 16.6458 14.2613 17.2394 13.2474 17.6766C12.2335 18.1144 11.1502 18.3333 9.9974 18.3333ZM9.9974 16.6666C11.8585 16.6666 13.4349 16.0208 14.7266 14.7291C16.0182 13.4375 16.6641 11.8611 16.6641 9.99996C16.6641 8.13885 16.0182 6.56246 14.7266 5.27079C13.4349 3.97913 11.8585 3.33329 9.9974 3.33329C8.13628 3.33329 6.5599 3.97913 5.26823 5.27079C3.97656 6.56246 3.33073 8.13885 3.33073 9.99996C3.33073 11.8611 3.97656 13.4375 5.26823 14.7291C6.5599 16.0208 8.13628 16.6666 9.9974 16.6666Z'
											fill='white'
										/>
									</g>
								</svg>
							</div>
							<p>{customToastMessage}</p>
						</div>
						{showDetails !== 0 ? (
							<p
								style={{ textDecoration: 'underline', cursor: 'pointer' }}
								onClick={() => {
									setHasQuantityDecreased &&setHasQuantityDecreased(false);
									setshowStockProductDecreasedModal(true);
								}}>
								Details
							</p>
						) : null}
					</div>
				</div>
			</div>
		</>
	);
};

export default CustomToast;
